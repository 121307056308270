import React, { useState, useEffect } from "react"
import FormTab from "components/formTab"
import Loader from 'components/loader'
import { cloneDeep } from 'lodash'
import FadeCollapse from 'src/components/fadeCollapse'
import { isEqual } from 'lodash'

import { emailIsValid } from 'utils'

import countryCodes from 'utils/countryCodes'

import axios from 'axios'

export default ({ 
    form, 
    handleSubmit,
    isLoading,
    error
}) => {
    const [fieldValues, setFieldValues] = useState({})
    const [errors, setErrors] = useState({})
    const [checkboxChecked, setCheckboxChecked] = useState(false)

    const activeSection = form ? form.formFields.sections[0] : null

    const {
        buttonLabel,
        checkboxConfirmCopy,
        sections
    } = form.formFields

    useEffect(() => {
        let hasCountryField = false

        sections.forEach(section => {
            section.rows.forEach(row => {
                row.columns.forEach(column => {
                    if(column.label === 'Country'){
                        hasCountryField = true
                    }
                })
            })
        })

        if(hasCountryField){
            axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=462f5e9b54af4b389288c77531d74905`)
                .then(({ data }) => {
                    const { country_name } = data

                    handleChange('Country', {
                        label: country_name,
                        value: country_name
                    })
                }).catch(error => {
                    console.log(error)
                })
        }
    }, [])

    const handleChange = (inputKey, value) => {
        const _fieldValues = cloneDeep(fieldValues)

        const phoneKey = 'Phone Number'

        if(inputKey === 'Country' && !fieldValues[phoneKey]){
            const country = countryCodes.find(country => country.name === value.value)

            _fieldValues[phoneKey] = country.dial_code
        }
        
        _fieldValues[inputKey] = value

        setFieldValues(_fieldValues)
    }

    const checkIfValid = () => {
        let _isValid = true
        const _errors = {}

        if(checkboxConfirmCopy && !checkboxChecked){
            _isValid = false
        }

        activeSection.rows.forEach(row => {
            row.columns.forEach(({ label }) => {
                const value = fieldValues[label]

                if(!fieldValues[label]){
                    _isValid = false
                }else{
                    switch(label){
                        case 'Confirm Password':
                            if(fieldValues['Password'] !== value){
                                _errors[label] = true
                            }
                            break
                        case 'Email Address':
                        case 'Email':
                            if(!emailIsValid(value)){
                                _errors[label] = true
                            }
                            break
                    }
                }
            })
        })

        if(!isEqual(_errors, errors)){
            setErrors(_errors)
        }

        return _isValid
    }

    const isValid = checkIfValid()

    return (
        <>
            { activeSection ?
                <form 
                    className="form-container" 
                    onSubmit={e => {
                        e.preventDefault();
                        handleSubmit(fieldValues)
                    }}
                >
                    <Loader visible={isLoading} />
                    <FadeCollapse open={!!error}>
                        <div className="error">
                            <i className="fa fa-exclamation-triangle" />
                            { error }
                        </div>
                    </FadeCollapse>
                    <div className="form-tabs">
                        <div className={"tab"}>
                            <FormTab 
                                section={activeSection} 
                                handleChange={handleChange}
                                values={fieldValues}
                                errors={errors}
                            />
                        </div>
                        { checkboxConfirmCopy ? 
                            <label className={`checkbox-confirm-copy ${ checkboxChecked ? 'checked' : '' }`}>
                                <div className={'checkbox'}>
                                    <input 
                                        type={'checkbox'} 
                                        checked={checkboxChecked}
                                        onChange={() => setCheckboxChecked(!checkboxChecked)}
                                    />
                                    <span />
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: checkboxConfirmCopy}} className="copy" />
                            </label>
                        : null }
                        <button 
                            type="submit" 
                            className='button outline'
                            disabled={!isValid}
                        >
                            { buttonLabel || 'Submit' } 
                        </button>
                    </div>
                </form>
            : null 
            }
        </>
    )
}