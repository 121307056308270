import React, { useContext } from 'react'
import Button from 'components/button'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import Slider from 'components/slider'
import { MainContext } from 'context/context'

import { useIsMobile } from 'utils'

export default ({
    properties,
}) => {

	const {
        openModal,
    } = useContext(MainContext)

    const isMobile = useIsMobile()

    const openStory = property => {
        if(property && property.title){
            openModal({
                type: 'PROPERTY',
                data: property
            })
        }
    }

    const propertiesJsx = properties.map(({ property }, index) => {
        const {
            title,
            propertyFields,
            featuredImage,
        } = property

        const {
            beds,
            baths,
            cars,
            floorplan,
        } = propertyFields


        return (
            <li 
                key={index}
                style={{
                    transitionDelay: (index/5 + 0.7)+'s'
                }}
            >
                <div className="content">
                    <a onClick={() => {openStory(property)}}>
                        <div className="image">
                            <div className="image-inner" style={{backgroundImage: "url(" + featuredImage.mediaItemUrl + ")"}} />
                        </div>
                        <div className="info">
                            <div className="title-row">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: title}} />
                                <i className="fe fe-plus"></i>
                            </div>
                            <div className="details-row">
                                <ul className="details">
                                    <li>
                                        <span>
                                            { beds }
                                        </span>
                                        <img src={require(`../images/bedicon.svg`)}/>
                                    </li>
                                    <li>
                                        <span>
                                            { baths }
                                        </span>
                                        <img src={require(`../images/bathicon.svg`)}/>
                                    </li>
                                    <li>
                                        <span>
                                            { cars }
                                        </span>
                                        <img src={require(`../images/caricon.svg`)}/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </a>
                    <a className="view" href={floorplan ? floorplan.mediaItemUrl : null} target="_blank">
                        View floorplan
                    </a>
                </div>
            </li>
        )
    })

    return (
        <div className="showcase">
            <div className="container">
                <ul className="grid">
                    {
                        propertiesJsx        
                    }
                </ul>
            </div>
        </div>
    )
}