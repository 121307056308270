import React, {useRef, useEffect} from 'react'
import Flickity from 'react-flickity-component'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import { isMobile } from 'utils'

export default ({
   images,
   copy,
}) => {
    const imageColumn = useRef(null)
    const imageColumnHeight = imageColumn.current ? imageColumn.current.clientHeight : null

    let imageColumnStyle = null

    if(!isMobile) {
        imageColumnStyle = {
            height: imageColumnHeight+'px'
        }
    }

    return (
        <div className="container">
            <div className="columns">
                <div className="column image" ref={ref => imageColumn.current = ref}>
                    { typeof window != 'undefined' &&
                        <Flickity
                            className={'carousel'}
                            options={{
                                prevNextButtons: false,
                                autoPlay: 3000,
                                infinite: true,
                                pageDots: false,
                                autoPlay: true,
                                contain: true,
                            }}
                        >
                           {images.map((image, index) => {
                                return (
                                    <div key={index} className="slide" style={{backgroundImage: `url('${image.image.mediaItemUrl}')`}}/>
                                )
                            })}
                        </Flickity>
                    }
                </div>
                <div className="column text" style={imageColumnStyle}>
                    <div className="copy" dangerouslySetInnerHTML={{__html: copy}}/>
                </div>
            </div>
        </div>
    )
}