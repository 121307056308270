import React, { 
    useRef,
    useEffect,
    useContext,
} from 'react'

import { MainContext } from "context/context"

import { useIsMobile } from 'utils'

export default ({
    heading,
    image
}) => {

    const {
		options,
    } = useContext(MainContext)
    
    const {
        logos
    } = options

    const backgroundUrl = `url('${image.mediaItemUrl}')`

    return (
        <>
            <div className="hero" style={{backgroundImage: backgroundUrl}}>
                <div className="container">
                    <div className="heading">
                        <img className="logo" src={logos.standardWhite.sourceUrl}/>
                        <h4>
                            Malvern East
                        </h4>
                    </div>
                </div>
            </div>
        </>
    )
}