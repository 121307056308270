import React from 'react'
import Form from 'components/form'
import { SUBMIT_FORM } from '../graphql/queries'
import { useLazyQuery, useMutation } from '@apollo/client'

export default ({
   heading,
   copy,
   form,
}) => {

    const [submitForm, { data: submitFormData, loading: submitFormLoading, error: submitFormError }] = useMutation(SUBMIT_FORM)

    const handleSubmit = data => {
        const json = JSON.stringify(data)

        submitForm({
            variables: {
                data: json
            },
        }).then((result) => {
            const success = result.data.submitForm.success

            console.log(success)

        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <div className="container">
            <div className="columns">
                <div className="text-column">
                    <h2>
                        {heading}
                    </h2>
                    <div className="copy" dangerouslySetInnerHTML={{__html: copy }}/>
                </div>
                <div className="form-column">
                    <Form form={form} handleSubmit={handleSubmit} isLoading={submitFormLoading} isError={submitFormError}/>
                </div>
            </div>
        </div>
    )
}