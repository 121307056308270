import gql from 'graphql-tag'
import { graphql } from "gatsby"

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
    ) {
        submitForm (
            input: {
                data: $data
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`

export const FormFieldsFragment = graphql`
  fragment FormFieldsFragment on WPGraphQL_Form {
    formFields {
        sections {
            title
            rowsHeading
            copy
            rows {
                heading
                columns {
                    label
                    options
                    type    
                }
            }
        }
        buttonLabel
        checkboxConfirmCopy
    }
}
`
