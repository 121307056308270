import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { MainContext } from "context/context"

import SEO from "components/seo"
import FlexibleContent from "components/flexibleContent"

export default ({ data }) => {
	const {
		databaseId,
		slug,
		excerpt,
		flexibleContent,
	} = data.siteFields.page

	const {
		pageTitle,
	} = flexibleContent

    const {
		setCurrentPage
	} = useContext(MainContext)
	

	return (
		<>
			<SEO title={pageTitle} description={excerpt} />
			<FlexibleContent
				id={databaseId}
				content={flexibleContent}
				slug={slug}
			/>
		</>
	)
}

export const query = graphql`
	query($databaseId: ID!) {
		siteFields {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				slug
				date
				content(format: RENDERED)
				featuredImage {
					altText
					title(format: RENDERED)
					mediaItemUrl
					slug
				}
				flexibleContent {
					pageTitle
					flexibleContent {
						__typename
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
							heading
							image {
								mediaItemUrl
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Heading {
							heading
							align
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Showcase {
							properties {
								property {
									... on WPGraphQL_Property {
										title
										featuredImage {
											mediaItemUrl
										}
										propertyFields {
											floorplan {
												mediaItemUrl
											}
											beds
											baths
											cars
											gallerySlides {
												image {
													mediaItemUrl
												}
												text
											}
										}
									}
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_FormSection {
							heading
							copy
							form {
								... on WPGraphQL_Form {
									...FormFieldsFragment
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_TwoColumnText {
							heading
							subtitle
							copy
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ImageAndText {
							images {
								image {
									mediaItemUrl
								}
							}
							copy
						}
					}
				}
			}
		}
	}
`
