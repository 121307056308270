import React from 'react'
import Form from 'components/form'

export default ({
   heading,
   subtitle,
   copy,
}) => {
    return (
        <div className="container">
            <div className="columns">
                <div className="column">
                    <h2 
                        dangerouslySetInnerHTML={{__html: heading}}>
                    </h2>
                    <h4>
                        {subtitle}
                    </h4>
                </div>
                <div className="column">
                    <div className="copy" dangerouslySetInnerHTML={{__html: copy}}></div>
                </div>
            </div>
        </div>
    )
}