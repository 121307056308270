import React from 'react'

export default ({
   heading,
   align
}) => {
    return (
        <div className="container">
            <h2 className={`align-${align}`}>
                {heading}
            </h2>
        </div>
    )
}