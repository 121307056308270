import React from 'react'

import TextInput from "components/textInput"
import TextArea from "components/textArea"
import Select from 'components/formFields/selectBox'

export default ({
    column, 
    values, 
    handleChange,
    error
}) => {
    let Component = null 
    let className = ''

    switch(column.type) {
        case 'text':
        case 'email':
        case 'password':
            Component = TextInput
            break;
        case 'select':
            className = 'select'
            Component = Select
            break;
        case 'textarea':
            Component = TextArea
            break
        }

    const inputKey = column.label

    return (
        <div className={error ? 'has-error' : ''}>
            <label>
                {column.label}
            </label>
            <Component 
                label={column.label} 
                options={column.options} 
                inputKey={inputKey}
                handleChange={value => handleChange(inputKey, value)}
                className={className}
                value={values[inputKey] || undefined}
                type={column.type}
            />
        </div>
    )
}